/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-super-a-powerhouse-of-comfort-in-your-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-super-a-powerhouse-of-comfort-in-your-ear",
    "aria-label": "widex super a powerhouse of comfort in your ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex Super: A Powerhouse of Comfort in Your Ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your days of riding in the car with your friends or family and missing parts of the conversation due to hearing loss are over! No more missing out on important moments in your life! Widex Super ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " is a MUST-HAVE for people suffering ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "from severe to profound hearing loss"), ". No matter what your lifestyle, the Super will bring a whole new world of sound into your life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This hearing aid is a marvel of modern ingenuity but that doesn’t mean it has to be bulky or uncomfortable. The Super is small and unnoticeable. It’s discrete and powerful. It will create a clarity of sound that has been otherwise unattainable. Everyone’s hearing loss is different, but with the Super you will find yourself hearing noises that you’ve been missing out on with previous hearing aids. What if your car is making a strange noise and needs to get checked out sooner rather than later? Do you enjoy sitting in the park and listening to birds chirp? Now it’s possible to hear those sounds and more!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "no-more-excuse-me-can-you-repeat-that",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-more-excuse-me-can-you-repeat-that",
    "aria-label": "no more excuse me can you repeat that permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No More, “Excuse Me, Can You Repeat That?”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s a mistake not to highlight the significant social advantages the Super brings to your life. Imagine you’re driving on the freeway, with a normal hearing aid you may struggle to hear what a passenger behind you is saying. With the Super, you can pick up the voices behind you! Your friends and family won’t have to shout louder than may be comfortable for them and you won’t have to interrupt them to ask them to. It’s leaps and bounds ahead of the competition. If you’re talking to a child, do you ever get annoyed quickly because their voice is too shrill or nasal? The Super will tone down those sounds so you can have conversations with your kids or grandkids without irritation. Parties, restaurants, or concerts, the Super is the ultimate social upgrade."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dont-compromise-your-lifestyle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dont-compromise-your-lifestyle",
    "aria-label": "dont compromise your lifestyle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Don’t Compromise Your Lifestyle!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re active, don’t worry about the Super getting in the way of your lifestyle. It’s totally weather-proof! With the WeatherCover (patent pending) and nano coating, rain and wind aren’t a factor. Plus with the incredibly long-lasting battery, you don’t have to worry about it for up to three weeks. It can be dangerous going out for a run or hike without your hearing aid, but with the Super, exercise and exciting outdoor activities are safe. If you’re on vacation and you’ve never tried white water rafting, you don’t even have to hesitate. One of the best things about the Super is that you can put it in and forget it’s even there!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "an-easy-to-use-tech-marvel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#an-easy-to-use-tech-marvel",
    "aria-label": "an easy to use tech marvel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "An Easy-To-Use Tech Marvel"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Super is a powerful hearing aid, but it’s also sophisticated. We all talk on the phone whether it’s to friends and family or for work. With the Super, you can get your phone calls sent directly to your hearing aid! This vastly improves call clarity and prevents any outside interference with your calls. Not tech savvy? Don’t worry! The experts at hear.com can quickly and easily get you started and answer any questions you may have."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Why does all of this make Widex’s Super model, super? It doesn’t just assist with hearing, it creates a “sound picture”. The world around you will open up with new sounds and easier social interactions. The “comfort” of the Super isn’t just physical. Yes, it’s tiny and easily forgettable but the ability to be able to do that is priceless. Not constantly worrying about your hearing aid is super in itself and being able to access sounds that previously couldn’t be heard is freedom."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re Here to Help!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So if you’re looking to maintain your current active lifestyle without compromising sound quality and availability, contact our hearing experts today and they can help you get started. Hear.com and it’s dedicated staff are waiting to guide you effortlessly through the process. They can answer any questions, get you financial assistance, and if after trying the Widex Super you decide that you don’t like it then you send it back and they find you a hearing aid you like. It’s that simple! Call or click the button below to try and qualify for a free 45-day risk-free trial."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Widex Super now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
